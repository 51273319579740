* {
  font-family: "Segoe UI", sans-serif;
  box-sizing: border-box;
}
body {
  margin: 0;
}
.wrapper {
  width: 600px;
  margin: 0 auto;
}
#timeval,
#targetval {
  font-size: 2em;
  border: 1px solid #999;
  padding: 5px;
  width: 100%;
}
#time_output {
  font-size: 4em;
  padding: 1rem 0;
}
h1 {
  font-size: 3rem;
}
.input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem 1rem;
}
@media (max-width: 420px) {
  .wrapper {
    max-width: 90vw;
  }
}
.vars {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}
.vars .var-container {
  display: flex;
  gap: 0 15px;
}
.vars .var-container input {
  font-size: 2rem;
  padding: 5px;
  width: 50%;
}
